@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap');
* {
}
.App{
    background-image: url('https://www.santatracker.live/static/images/bg1.png');
    color: #333;
}
.App {
    text-align: center;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.countdownh1 {
    position: relative;
    margin: a;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
    width: 350px;
    font-family: Lobster Two;
    margin: 0;
    padding: 0 ;
}
.countdownh1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin: .3rem;
    padding: 0 ;
}
.countdownh1{
    font-size: 4rem;
    margin: 0;
    padding: 0 ;
}
.main-container {
    font-family: 'Jost', sans-serif;
}
.welcome{
    font-family: 'Jost', sans-serif;
    font-size: 110%;
    font-style: thin;
    margin: 20% 0 0 0;
    padding: 0 ;
    font-weight: 700;
}
.stl{
    font-family: Lobster Two;
    font-size: 350%;
    margin: 0;
    padding: 0 ;
    font-weight: 700;
}