/* Rectangle 10 */
.MetricBox{
    box-sizing: border-box;
position: absolute;
width: 119px;
height: 320px;

background: #FFFCED;
border: 3px solid #E63946;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
transform: rotate(90deg);
}

.MBoxTitle{
    color: #E63946;
}
.MboxStats{
    color: black;
}